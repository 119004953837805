.nav-link {
	@apply no-underline lg:pr-10 pr-4 ml-2 inline-block relative;
}

.cart-info {
	@apply absolute bg-white -left-full transform -translate-x-10 md:-translate-x-1/3 z-20 text-sm text-center align-baseline font-normal w-80 max-h-96 shadow-lg transition-opacity px-7 py-5 text-gray-500 opacity-0 group-hover:opacity-100;
}

.categories-dropdown {
	@apply absolute bg-white w-52 z-20 text-black text-sm text-center align-baseline font-normal transition-opacity py-2 px-1 opacity-0 group-hover:opacity-100;
}

.st0 {
	fill: #5a1526;
}
.st1 {
	fill: #e51947;
}
.top-navbar {
	@apply md:pl-10 md:pr-6 px-2;
}

@screen lg {
	.top-navbar {
		padding-left: 9.5rem;
		@apply pr-4;
	}
}

.whatsapp-animation {
	@apply transition-all duration-200 delay-200;
	right: -8rem;
	animation: easeOutBounce 1s linear 1s 1 normal forwards;
}

@keyframes easeOutBounce {
	0% {
		right: calc(2.5rem * 0);
	}

	12% {
		right: calc(2.5rem * 0.1089);
	}

	24% {
		right: calc(2.5rem * 0.4356);
	}

	36% {
		right: calc(2.5rem * 0.9801);
	}

	54% {
		right: calc(2.5rem * 0.7502);
	}

	74% {
		right: calc(2.5rem * 0.9837);
	}

	82% {
		right: calc(2.5rem * 0.9375);
	}

	92% {
		right: calc(2.5rem * 0.9934);
	}

	96% {
		right: calc(2.5rem * 0.9846);
	}

	100% {
		right: calc(2.5rem * 1);
	}
}
