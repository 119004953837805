@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
	font-family: 'Poppins', sans-serif;
}
.animated-link::before,
.nav-link::before {
	content: '';
	transform: scale(0, 1);
	@apply block absolute left-0 -bottom-px origin-right w-full transition-transform duration-200 ease-in bg-red-500 h-px;
}

.nav-link.active {
	@apply text-main-brown;
}

.animated-link.active::before,
.nav-link.active::before,
.nav-link:hover::before {
	transform-origin: left center;
	transform: scale(1, 1);
}
::selection {
	background-color: #c96;
}

body {
	background-image: url('./assets/triangle-mosaic.png');
}

.toast {
	@apply max-w-full text-sm pointer-events-auto;
	width: 21.875rem;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
	border-radius: 0.25rem;
}

.fade {
	@apply transition-opacity duration-150 ease-linear;
}

.toast-container {
	@apply w-max max-w-full pointer-events-none;
}

.toast-header {
	@apply flex items-center;
	padding: 0.5rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
	@apply p-2;
}
