.category-container {
	@apply mx-auto md:h-96 px-8 md:px-32 lg:px-40 w-10/12;
}

.category-banner {
	@apply relative my-1 h-80 overflow-hidden mx-auto w-full hover:opacity-95;
}

.subscribe-part {
	@apply flex flex-wrap -mx-1;
}
.subscribe-part > a,
.subscribe-part > div {
	height: 32rem;
}

@screen md {
	.category-banner {
		width: 48.26%;
		height: 32rem;
	}
	.category-container {
		margin-bottom: 8rem;
	}
}
