.img-container {
	@apply relative cursor-move !important;
}
.img-container img {
	@apply mx-auto max-w-none !important;
}

.list-auto-styling ol {
	padding-left: 1rem;
	@apply list-decimal list-inside;
}

.list-auto-styling ul {
	padding-left: 1rem;
	@apply list-disc list-inside;
}
